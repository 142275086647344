import React from "react"
import Layout from "../components/layout"
import EmailSub from "../components/emailSub"
import SEO from "../components/seo"
import styled from "@emotion/styled"

const JoinContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  margin-top: 10rem;
  margin-bottom: 5rem;
`

/* Header */
const JoinHeaderContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  width: 100%;
`

const JoinTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
`

const JoinLine = styled.div`
  background-color: #7798ab;
  width: 1rem;
  margin: 0 auto;
  height: 0.1rem;
  margin-top: 0.5rem;
`

const JoinText = styled.div`
  text-align: center;
  margin: 2rem 2rem 0 2rem;
`

const JoinInfo = styled.p`
  width: 80%;
  margin: 0 auto;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;

  @media (min-width: 768px) {
   font-size: 1.3rem;
   width: 55%;
   line-height: 1.75rem;
  }
`

const JoinPage = () => (
    <Layout>
      <SEO title="Insider" />
      <JoinContainer>
        <JoinHeaderContainer>
          <JoinTitle>Become An Insider</JoinTitle>
          <JoinLine>&nbsp;</JoinLine>
          <JoinText>
            <JoinInfo>Sign up for the inside scoop on new books, freebies and the latest news</JoinInfo>
          </JoinText>
        </JoinHeaderContainer>
        <EmailSub />
      </JoinContainer>
    </Layout>
  )
  
  export default JoinPage